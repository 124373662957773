import api from "./index";

export default {
  async addUpsell({ itemId, upsellId }) {
    try {
      const response = await api.post(`/cart_items/${itemId}/add_upsell`, {
        upsell_id: upsellId
      });
      return response;
    } catch (error) {
      return false;
    }
  },
  async removeUpsell({ itemId, upsellId }) {
    try {
      const response = await api.post(`/cart_items/${itemId}/remove_upsell`, {
        upsell_id: upsellId
      });
      return response;
    } catch (error) {
      return false;
    }
  },
  async removeUpsellFromCart({ itemId, upsellId, removeFully = false }) {
    try {
      const response = await api.post(
        `/cart_items/${itemId}/remove_upsell?decorate_cart=true`,
        {
          upsell_id: upsellId,
          remove_fully: removeFully
        }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async updateUpsellsQuantity({ itemId, upsellId, quantity }) {
    try {
      const response = await api.put(
        `/cart_items/${itemId}/update_upsell_quantity`,
        {
          upsell_id: upsellId,
          quantity
        }
      );
      return response;
    } catch (error) {
      return false;
    }
  }
};
